import { getTemplateContent } from "./util";
import { pushNewsletterSubscription } from "./gtm-event";

/**
 * Enhancer of the Job Opening form.
 * Will handle file uploads by uploading directly to S3 using a pre-signed URL, since attachments
 * might be to big to be an AWS Lambda invocation payload. (6MB)
 */
const UPLOAD_ERROR =
  "Uploading your attachment failed. Please try again later.";

const basename = (path) => path.split(/(\\|\/)/g).pop();

const s3putObject = (uri, file) =>
  fetch(uri, {
    method: "PUT",
    body: file,
  });

const populateFileMetadata = (fileInput, s3filename) => {
  const fileParent = fileInput.parentNode;
  fileParent.querySelector(
    '[data-is-original-filename="true"]'
  ).value = basename(fileInput.value);
  fileParent.querySelector('[data-is-s3-filename="true"]').value = s3filename;
};

const clearError = (fileInput) => {
  const fileParent = fileInput.parentNode;
  fileParent.querySelector(".js-file-error").innerHTML = "";
};

const renderError = (fileInput) => {
  const fileParent = fileInput.parentNode;
  fileParent.querySelector(".js-file-error").innerHTML = UPLOAD_ERROR;
};

const getUploadProcess = (getPresignedUrl) => (fileInput) =>
  fetch(getPresignedUrl, {
    method: "POST",
    mode: "cors",
  })
    .then((response) => response.json())
    .then(({ uri, filename }) =>
      s3putObject(uri, fileInput.files[0]).then((response) =>
        response.status !== 200
          ? Promise.reject(UPLOAD_ERROR)
          : { uri, filename }
      )
    )
    .then(({ uri, filename }) => populateFileMetadata(fileInput, filename))
    .catch((e) => {
      console.error(e);
      renderError(fileInput);
    });

export const enhancer = (form) => {
  const attachmentsTpl = form.querySelector("#js-attachments-fieldset");
  form.replaceChild(getTemplateContent(attachmentsTpl), attachmentsTpl);

  const uploadFile = getUploadProcess(form.getAttribute("data-s3-upload-url"));

  // Object store for keeping upload promises
  const fileUploads = {};

  // Grab all file type elements
  const fileInputs = [...form.querySelectorAll('input[type="file"]')];
  fileInputs.forEach((fileInput) => {
    // Listen for changes
    fileInput.addEventListener("change", (e) => {
      clearError(fileInput);
      // Push Promise to fileUploads
      fileUploads[fileInput.name] = uploadFile(fileInput);
    });
  });

  form.addEventListener("submit", (e) => {
    e.preventDefault();
    const submitButton = form.querySelector("button[type=submit]");
    submitButton.setAttribute("disabled", "disabled");
    submitButton.textContent = "Please wait…";

    // Wait for all file uploads to finish before submitting the form.
    Promise.all(Object.values(fileUploads)).then((_) => {
      // Send email to GTM
      const formData = new FormData(form);
      const email = formData.get("email");
      pushNewsletterSubscription(email);

      window.location.hash = "form-is-sent";
      form.submit();
    });
  });

  // Force a redirect with JS to ensure the form is empty when going back with the back-button.
  if (window.location.hash === "#form-is-sent") {
    // Works for Chrome only.
    // Safari and Firefox have a hanging submit button, so they will never repose anything again
    const [url, hash] = window.location.href.split("#");
    window.location.href = url;
  }
};
