import { publish, subscribe } from "./observer";
import {
  forceClosePopup,
  newsletterFormSuccess,
  newsletterFormFailure,
} from "./observer-subjects";
import { pushNewsletterSubscription } from "./gtm-event";

const STATUS_SELECTOR = `.js-newsletter-status`;

const NewsletterForm = (form) => {
  let isSubmitting;

  const statusEl = form.querySelector(STATUS_SELECTOR);

  /**
   * JSONP!
   * The email subscription form now talks to Pardot directly.
   * This isn't possible with a fetch (CORS) and must be done with JSONP.
   */
  const submitForm = ({ action, data }) => {
    const queryString = new URLSearchParams(data).toString();
    const url = `${action}?${queryString}`;

    const head = document.querySelector("head");

    // Create a script
    const scriptElement = document.createElement("script");

    // Update the script
    scriptElement.setAttribute("src", url);
    scriptElement.setAttribute("type", "text/javascript");
    scriptElement.setAttribute("data-id", "pardot-script");

    // Append script to the head.
    // Because the script contains the URL for Pardot (and the form data),
    // the sign-up is executed. Pardot will look for an object called pardotHandler.
    // This object should contain a method called callback. This method is
    // executed by Pardot.
    head.appendChild(scriptElement);
  };

  const handleSubmit = () => {
    if (isSubmitting) {
      return;
    }
    isSubmitting = true;

    const action = form.getAttribute("action");
    // Reverse action after the action is also reversed in the HTML.
    const reversedAction = action.split("").reverse().join("");

    statusEl.classList.remove("has-succeeded", "has-failed");
    statusEl.setAttribute("aria-hidden", "true");
    form.classList.add("is-submitting");

    const formData = new FormData(form);
    const email = formData.get("email");
    pushNewsletterSubscription(email);

    submitForm({ action: reversedAction, data: new FormData(form) });
  };

  /**
   * Show the status badge.
   */
  const showStatus = () => {
    form.classList.remove("is-submitting");
    statusEl.setAttribute("aria-hidden", "false");
    isSubmitting = false;
  };

  // Handle a successful subscription.
  const submitFormSuccess = () => {
    statusEl.innerHTML = "<span>Thank you for subscribing!</span>";
    statusEl.classList.add("has-succeeded");
    window.setTimeout(() => {
      form.querySelector('input[type="email"]').value = "";
    }, 200);

    // Automatically close popup after successful sign-up.
    window.setTimeout(() => {
      publish(forceClosePopup);
    }, 3000);

    showStatus();
  };

  // Handle a failing subscription.
  const submitFormFailure = () => {
    statusEl.innerHTML =
      "<span>Something went wrong, please try again...</span>";
    statusEl.classList.add("has-failed");
    window.setTimeout((e) => {
      statusEl.setAttribute("aria-hidden", "true");
    }, 2000);

    showStatus();
  };

  // Failsafe to only run function when this instance is submitting.
  const checkIfSubmitting = (fn) => {
    if (isSubmitting) {
      fn();
    }
  };

  return {
    init() {
      form.addEventListener("submit", (e) => {
        e.preventDefault();
        form.querySelector("button").blur();
        handleSubmit();
      });
      statusEl.addEventListener("click", (e) => {
        statusEl.setAttribute("aria-hidden", "true");
      });

      subscribe(newsletterFormSuccess, () =>
        checkIfSubmitting(submitFormSuccess)
      );
      subscribe(newsletterFormFailure, () =>
        checkIfSubmitting(submitFormFailure)
      );
    },
  };
};

export const enhancer = (form) => {
  const newsletterForm = NewsletterForm(form);
  newsletterForm.init();
};

// Pardot endpoint callback function.
// The name must be pardotHandler, therefore it's set as property of the window.
window.pardotHandler = {
  callback: (resp) => {
    if (resp.result === "success") {
      publish(newsletterFormSuccess);
    } else {
      publish(newsletterFormFailure);
    }

    // Cleanup Pardot script.
    const pardotScript = document.querySelector('[data-id="pardot-script"]');
    pardotScript.remove();
  },
};
